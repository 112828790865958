<script>
import { defineComponent } from 'vue'
import { Line } from 'vue3-chart-v2'

export default defineComponent({
    name: 'MonthlyChart',
    props: ['data'],
    extends: Line,
    data() {
        return {
            labels: [],
            valueColumn: [],
        }
    },
    
    created () {

        this.data.forEach(value => {
            var d = new Date(value[0]);
            var n = d.getDate();
            this.valueColumn.push(value[1])
            this.labels.push(n)
        });
    },
    mounted () {
        
        // Overwriting base render method with actual data.
        this.renderChart({
        labels: this.labels,
        datasets: [
            {
                label: 'Price ',
                backgroundColor: '#f87979',
                borderColor: [
                    '#E46651',
                ],
                borderWidth: 2,
                data: this.valueColumn
            }
        ]
    })
  }
})
</script>